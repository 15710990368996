import React from 'react';
import {
    Container
} from "react-bootstrap";

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            artist: null,
            userid: 'paalth@gmail.com'
          };
      }

    componentDidMount() {
    }

    render() {
        return (
          <Container>
            <h1>User account information </h1>

            TBD
          </Container>
        );
    }
}

export default User;