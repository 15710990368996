import React from 'react';
import {
  Container
} from "react-bootstrap";
import PerformanceComments from './PerformanceComments';

class PerformanceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      performance: null,
      userid: 'paalth@gmail.com'
    };
  }

  componentDidMount() {
    const { params } = this.props.match
    fetch(window._env_.API_URL + '/performance/' + params.id, {
        headers: {
          'Authorization': window._env_.FRONTEND_TOKEN,
          'UserId': this.state.userid
        }
      }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              performance: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )   
  }

  render() {
    const { error, isLoaded, performance } = this.state;
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
           <Container>
            <h1>{performance.Name}</h1>
            Description: {performance.Description}
            <p />
            When: {performance.When} 
            <p />
            Where: {performance.Where}
            <p />
            Media:
            <p />

            {performance.Media.map(media => (
              <div dangerouslySetInnerHTML={{ __html: media.MediaRef }} />
            ))}

            <PerformanceComments performanceId={performance.Id} />
          </Container>
        )
     };
  }
}

export default PerformanceView;