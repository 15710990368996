import React from 'react';
import {
  Link
} from "react-router-dom";
import {
  Container
} from "react-bootstrap";

class PersonView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      person: null,
      userid: 'paalth@gmail.com'
    };
  }

  componentDidMount() {
    const { params } = this.props.match
    fetch(window._env_.API_URL + '/person/' + params.id, {
        headers: {
          'Authorization': window._env_.FRONTEND_TOKEN,
          'UserId': this.state.userid
        }
      }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              person: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )   
  }

  render() {
    const { error, isLoaded, person } = this.state;
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
           <Container>
            <h1>{person.Name}</h1>
            Description: {person.Description}
            <p />
            {person.DoB.substring(0, 10)} - {person.DoD.substring(0, 10)}
            <p />
            Related artists:
            <ul>
            {person.Artists.map(artist => (
                    <li key={artist.Id}> <Link to={`/viewartist/${artist.Id}`}>{artist.Name}</Link></li>
                ))}
            </ul>
          </Container>
        )
     };
  }
}

export default PersonView;