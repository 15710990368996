import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Form,
  Button,
  Container,
  Col
 } from "react-bootstrap"

class PersonNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: '', 
      Description: '',
      DoB: '',
      PoB: '',
      DoD: '',
      userid: 'paalth@gmail.com'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    fetch(window._env_.API_URL + '/person', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Authorization': window._env_.FRONTEND_TOKEN,
        'UserId': this.state.userid,
        'Content-Type': 'application/json'
      }  
    }).then(response => {
      console.log("Successful");
    });

    event.preventDefault();
    this.props.history.push('/');
  }

  render() {
    return (
      <Container>
        <h1>Add person</h1>
        <Form>
          <Form.Group controlId="Name">
            <Form.Label>Name</Form.Label>
            <Form.Control autoFocus type="text" placeholder="Enter name" value={this.state.Name} onChange={e => this.setState({ Name : e.target.value })} />
          </Form.Group>
          <Form.Group controlId="Description">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Enter description" value={this.state.Description} onChange={e => this.setState({ Description : e.target.value })}  />
          </Form.Group>
          <Form.Row>
          <Form.Group controlId="Description">
            <Form.Label>Date of birth</Form.Label>
            <Form.Control type="text" placeholder="Enter date of birth" value={this.state.DoB} onChange={e => this.setState({ DoB : e.target.value })}  />
          </Form.Group>
          <Form.Group controlId="Description">
            <Form.Label>Place of birth</Form.Label>
            <Form.Control type="text" placeholder="Enter place of birth" value={this.state.PoB} onChange={e => this.setState({ PoB : e.target.value })}  />
          </Form.Group>
          </Form.Row>
          <Form.Row>
            <Col>
          <Button variant="primary" type="submit" onClick={this.handleSubmit}>Add</Button>
          </Col>
            <Col>
          <Button variant="secondary">Clear</Button>
          </Col>
            <Col>
          <Button variant="secondary">Cancel</Button>
          </Col>
          </Form.Row>
        </Form>
      </Container>
    );
  }
}

export default withRouter(PersonNew);