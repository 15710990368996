import React from 'react';
import {
  Container
} from "react-bootstrap";

class PerformanceComments extends React.Component {
  constructor(props) {
    super(props);
    console.log("PerformanceComments: performanceId = " + props.performanceId);
    this.state = {
        error: null,
        isLoaded: false,
        comments: null,
        performanceId: props.performanceId,
        userid: 'paalth@gmail.com'
      };
  }

  componentDidMount() {
    fetch(window._env_.API_URL + '/performance/' + this.state.performanceId + "/comments", {
        headers: {
          'Authorization': window._env_.FRONTEND_TOKEN,
          'UserId': this.state.userid
        }
      }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              comments: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )   
  }

  render() {
    const { error, isLoaded, comments } = this.state;
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
           <Container>
            <h1>Comments</h1>
            <ul>
                {comments.map(comment => (
                    <li key={comment.Id}> {comment.CreationDate} - {comment.UserDisplayName} - {comment.Comment}</li>
                ))}
            </ul>
          </Container>
        )
      }
  };
}

export default PerformanceComments;