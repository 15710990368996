import React from 'react';
import {
    Link
} from "react-router-dom";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import ArtistComments from './ArtistComments';

class ArtistView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        error: null,
        isLoaded: false,
        artist: null,
        userid: 'paalth@gmail.com'
      };
  }

  componentDidMount() {
    const { params } = this.props.match
    fetch(window._env_.API_URL + '/artist/' + params.id, {
        headers: {
          'Authorization': window._env_.FRONTEND_TOKEN,
          'UserId': this.state.userid
        }
      }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              artist: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )   
  }

  render() {
    const { error, isLoaded, artist } = this.state;
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
           <Container>
             <Row>
                <h1>{artist.Name}</h1>
             </Row>
             <Row>
               <Col>
                Description: {artist.Description}
               </Col>
             </Row>
             <Row>
               <Col>
                My favorite performances:
               </Col>
              <Col>
                Persons:
               </Col>
              <Col>
                Related artists:
              </Col>
             </Row>
             <Row>
               <Col>
                <ul>
                  {artist.Performances.map(performance => (
                    <li key={performance.Id}> <Link to={`/viewperformance/${performance.Id}`}>{performance.Name}</Link></li>
                  ))}
                </ul>
               </Col>
               <Col>
                <ul>
                  {artist.Persons.map(person => (
                    <li key={person.Id}> <Link to={`/viewperson/${person.Id}`}>{person.Name}</Link></li>
                  ))}
                </ul>
               </Col>
               <Col>
               &nbsp;
               </Col>
             </Row>
             <Row>
              <ArtistComments artistId={artist.Id} />
             </Row>
          </Container>
        )
     };
  }
}

export default ArtistView;
