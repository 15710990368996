import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col
 } from "react-bootstrap";
import { auth, provider } from './firebase.js';
import Artists from './artist/Artists.js';
import ArtistsAll from './artist/ArtistsAll.js';
import ArtistNew from './artist/ArtistNew.js';
import ArtistView from './artist/ArtistView.js';
import PerformanceView from './performance/PerformanceView.js';
import Persons from './person/Persons.js';
import PersonNew from './person/PersonNew.js';
import PersonView from './person/PersonView.js';
import User from './User.js';
import About from './About.js';
import NotFound from './NotFound';

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login() {
    auth.signInWithPopup(provider) 
      .then((result) => {
        console.log("Login");
        const resultUser = result.user;
        this.setState({
          user: resultUser
        });
      });
  }

  logout() {
    auth.signOut()
    .then(() => {
      console.log("Logout");
      this.setState({
        user: null
      });
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: user  });
      } 
    });
  }

  render() {
  return (
    <Router>
      <div>
         <Navbar bg="dark" variant="dark">
         <Container>
            <Row noGutters className="position-relative w-100 align-items-center">
            <Col className="d-none d-lg-flex justify-content-start">

           <Navbar.Brand href="/">Undeniable Talent</Navbar.Brand>
           <NavDropdown title="Content" id="basic-nav-dropdown">
             <NavDropdown.Item href="/artists">Artists</NavDropdown.Item>
             <NavDropdown.Item href="/performances">Performances</NavDropdown.Item>
             <NavDropdown.Item href="/performances">Collections</NavDropdown.Item>
             <NavDropdown.Item href="/persons">Persons</NavDropdown.Item>
             <NavDropdown.Item href="/performances">Events</NavDropdown.Item>
           </NavDropdown>

            </Col>

            <Col className="d-none d-lg-flex justify-content-end">

            {this.state.user ?
              <div>
                <Nav.Link href="/user">{this.state.user.email}</Nav.Link>
              </div>
              :
              <div className='wrapper'>
                <p>N/A</p>
              </div>
            }
           {this.state.user ?
             <button onClick={this.logout}>Log Out</button>                
             :
             <button onClick={this.login}>Log In</button>              
           }
           <Nav.Link href="/about">About</Nav.Link>

            </Col>
          </Row>
        </Container>
        </Navbar>

        <Switch>
          <Route path="/persons">
            <Persons />
          </Route>
          <Route path="/allartists">
            <ArtistsAll />
          </Route>
          <Route path="/newartist">
            <ArtistNew />
          </Route>
          <Route path="/newperson">
            <PersonNew />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/user">
            <User />
          </Route> 
          <Route path="/viewartist/:id" component={ArtistView} />
          <Route path="/viewperformance/:id" component={PerformanceView} />
          <Route path="/viewperson/:id" component={PersonView} />
          <Route path="/">
            <Artists />
          </Route>
          <Route >
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
}

export default App;
