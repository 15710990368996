import React from 'react';
import {
  Container, 
  Row,
  Form,
  Button,
  Nav
} from "react-bootstrap";

class ArtistComments extends React.Component {
  constructor(props) {
    super(props);
    console.log("ArtistComments: artistId = " + props.artistId);
    this.state = {
        showCreateComment: false,
        error: null,
        isLoaded: false,
        comments: null,
        newComment: null,
        artistId: props.artistId,
        userid: 'paalth@gmail.com'
      };

      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggleShowHide = this.toggleShowHide.bind(this);
  }

  componentDidMount() {
    fetch(window._env_.API_URL + '/artist/' + this.state.artistId + "/comments", {
        headers: {
          'Authorization': window._env_.FRONTEND_TOKEN,
          'UserId': this.state.userid
        }
      }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              comments: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )   
  }

  handleSubmit(event) {
    fetch(window._env_.API_URL + '/artist/' + this.state.artistId + "/comments", {
      method: 'POST',
      body: '{"Comment": "' + this.state.newComment + '"}',
      headers: {
        'Authorization': window._env_.FRONTEND_TOKEN,
        'UserId': this.state.userid,
        'Content-Type': 'application/json'
      }  
    }).then(response => {
      console.log("Successful");
      this.setState({
        showCreateComment: false,
        newComment: null
      });
    });

    event.preventDefault();
  }

  toggleShowHide() {
    this.setState(oldState => ({showCreateComment: !oldState.show}));
  }

  render() {
    const { showCreateComment, error, isLoaded, comments } = this.state;
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
           <Container fluid>

             <Row>
              <h2>Comments</h2>
              <Nav.Link onClick={() => this.toggleShowHide()}>Add</Nav.Link>
             </Row>

             <Row style={{ visibility: this.state.showCreateComment ? "visible" : "hidden", }}>
              <Form inline>
                <Form.Group controlId="NewComment">
                  <Form.Control autoFocus type="text" placeholder="Enter new comment" value={this.state.newComment} onChange={e => this.setState({ newComment : e.target.value })} />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={this.handleSubmit}>Create</Button>
                <Button variant="secondary">Cancel</Button>
              </Form>
             </Row>

             <Row>
              <ul>
                {comments.map(comment => (
                    <li key={comment.Id}> {comment.CreationDate} - {comment.UserDisplayName} - {comment.Comment}</li>
                ))}
              </ul>
             </Row>
          </Container>
        )
      }
  };
}

export default ArtistComments;