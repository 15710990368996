import React from 'react';
import {
  Link
} from "react-router-dom";
import {
  Card,
  CardColumns,
  Container,
  Row,
  Col,
  Nav
 } from "react-bootstrap";

class Artists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      userid: 'paalth@gmail.com'
    };
  }

componentDidMount() {
  fetch(window._env_.API_URL + '/artists', {
    headers: {
      'Authorization': window._env_.FRONTEND_TOKEN,
      'UserId': this.state.userid
    }
  }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
}

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Container>
          <Row>
            <Col>
              <h1>My favorite artists</h1>
            </Col>
            <Col>&nbsp; </Col>
            <Col>
              <Nav className="justify-content-center" activeKey="/home">
                <Nav.Item>
                  <Nav.Link href="/newartist">Add artist</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/allartists">View all artists</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <CardColumns>
            {items.map(item => (
              <Card border="secondary" style={{ width: '18rem' }} key={item.Id} >
                <Card.Header><Link to={`/viewartist/${item.Id}`}>{item.Name}</Link></Card.Header>
                <Card.Body>
                  <Card.Title>{item.Description}</Card.Title>
                </Card.Body>
                <Card.Footer>
                  <small>Thumbs Up: 0 - Thumbs Down: 0</small>
                </Card.Footer>
              </Card>
            ))}
          </CardColumns>
         </Container>
      );
    }
  }

}

export default Artists;