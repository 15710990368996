import React from 'react';
import { Container } from 'react-bootstrap';

class About extends React.Component {
    render() {
        return (
            <Container>
                <h1>About this site</h1>
                This site bla bla bla....
            </Container>
        );
    }
}

export default About;