import React from 'react';
import {
  Link
} from "react-router-dom";
import {
  Table,
  Container,
  Row,
  Col
 } from "react-bootstrap";

class ArtistsAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      userid: 'paalth@gmail.com'
    };
  }

componentDidMount() {
  fetch(window._env_.API_URL + '/artists/all', {
    headers: {
      'Authorization': window._env_.FRONTEND_TOKEN,
      'UserId': this.state.userid
    }
  }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
}

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Container>
          <Row>
            <Col>
              <h1>All artists</h1>
            </Col>
            <Col>&nbsp; </Col>
            <Col className="d-lg-flex justify-content-end">
              <Link to="/newartist">Add artist</Link>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>Artist</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {items.map(item => (
            <tr>
              <td><Link to={`/viewartist/${item.Id}`}>{item.Name}</Link></td>
              <td>Add as favorite</td>
            </tr>
            ))}
            </tbody>
            </Table>
          </Row>
         </Container>
      );
    }
  }

}

export default ArtistsAll;