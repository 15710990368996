import firebase from 'firebase'

const config = {
        apiKey: "AIzaSyDkWqbqExGjnrntN0rirGKpIsgWVP-0ao0",
        authDomain: "undeniabletalent.firebaseapp.com",
};

firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;
